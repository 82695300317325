<template>
  <q-layout view="hHh lpR fFf">
    <q-drawer v-model="drawer" bordered side="right" show-if-above>
      <drawer-tabs />
    </q-drawer>
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useAppStore } from "src/stores/app";
import DrawerTabs from "src/components/drawer/DrawerTabs.vue";

defineOptions({ name: "MainLayout" });

const appStore = useAppStore();
const { drawer } = storeToRefs(appStore);
</script>
