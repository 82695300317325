<template>
  <q-item-label class="inter-700 text-caption text-dark" header>{{
    data.title
  }}</q-item-label>
  <q-list class="q-mb-lg" dense>
    <q-item
      v-for="(project, index) in data.projects"
      clickable
      :key="project._key"
      @click="onClick(realIndex(index), project)"
    >
      <q-item-section>
        <q-item-label caption class="text-dark">
          {{ project.title }}
        </q-item-label>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script setup>
import { useQuasar } from "quasar";
import { storeToRefs } from "pinia";
import { useAppStore } from "src/stores/app";
import DialogProjects from "src/components/dialog/DialogProjects.vue";

defineOptions({ name: "WorkItem" });

const props = defineProps({
  data: { type: Object, default: () => {} },
  workIndex: { type: Number, default: 0 },
  startIndex: { type: Number, default: 0 },
});

const $q = useQuasar();
const appStore = useAppStore();
const { projects } = storeToRefs(appStore);

const realIndex = (index) => {
  return props.startIndex + index;
};

const onClick = (realIndex, project) => {
  $q.dialog({
    component: DialogProjects,
    componentProps: {
      data: project,
      index: realIndex,
      projects: projects.value,
    },
  });
};
</script>
